body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border-collapse: collapse;
  border-style: hidden;
  border-bottom: 1px solid black;
}

td,
tr,
th {
  border: 1px solid black;
}

tr:nth-child(2n + 1) {
  background-color: #f3f9f5;
}

tr:first-child th {
  border-bottom: 2px solid black;
}

th:first-child {
  border-right: 2px solid black;
}

td,
th {
  padding: 5px;
}
