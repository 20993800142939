@media print {
  body {
    background: none !important;
    color: black !important;
    margin: 0%;
  }

  header,
  footer,
  #footerLinks,
  #scrollButton,
  #articleMenu {
    display: none !important;
  }

  h1,
  h2,
  h3,
  p {
    page-break-inside: avoid;
    color: black;
  }

  .factbox {
    border: 1px solid black;
  }
}
