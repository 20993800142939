.map-container {
  height: 98%;
  border: 1px solid gray;
}

div.ol-zoom {
  width: 1em;
  position: absolute;
  top: 1em;
  left: 1em;
}

.ol-control button.ol-zoom-in,
.ol-control button.ol-zoom-out {
  margin: 0.2em;
  background-color: black;
  color: white;
  border: 0;
  padding: 0em 0.4em 0.1em 0.4em;
  border-radius: 0.2em;
  font-size: 2em;
}

.station-description {
  padding: 0.5em;
  background-color: black;
  color: white;
  border-radius: 0.5em;
  border: 1px solid black;
  text-align: center;
}

.selection-panel-container {
  text-align: center;
}

div.selection-panel {
  width: 17em;
  border-radius: 0.5em;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: 0px none transparent;
  text-align: center;
  top: calc(100% - 5em);
  left: 1em;
}

div.selection-panel:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

div.alert-panel {
  background-color: 'inherit'; /*rgba(256, 0, 0, 0.6); */
}

div.alert-panel:hover {
  background-color: 'inherit'; /*rgba(256, 0, 0, 0.7);*/
}

.symbolDescImg {
  margin: 0em 0.5em;
  vertical-align: bottom;
  width: 1em;
}
.symbolDescText {
  vertical-align: bottom;
}
/* default openlayer classes below */
.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid gray;
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute;
}

.ol-scale-line {
  background: rgba(0, 60, 136, 0.3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute;
}

.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
}

.ol-overlay-container {
  will-change: left, right, top, bottom;
}

.ol-unsupported {
  display: none;
}

.ol-unselectable,
.ol-viewport {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  padding: 2px;
}

.ol-control:hover {
  background-color: 'inherit';
  /*    background-color: rgba(255, 255, 255, .6)*/
}

.ol-zoom {
  top: 0.5em;
  left: 0.5em;
}

.ol-rotate {
  top: 0.5em;
  right: 0.5em;
  transition: opacity 0.25s linear, visibility 0s linear;
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s linear, visibility 0s linear 0.25s;
}

.ol-zoom-extent {
  top: 4.643em;
  left: 0.5em;
}

.ol-full-screen {
  right: 0.5em;
  top: 0.5em;
}

@media print {
  .ol-control {
    display: none;
  }
}

.ol-control button {
  display: block;
  margin: 1px;
  padding: 0;
  color: #fff;
  font-size: 2em;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: 0.4em;
  background-color: rgba(0, 0, 0, 1);
  border: none;
  border-radius: 0.2em;
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0;
}

.ol-zoom-extent button {
  line-height: 1.4em;
}

.ol-compass {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  will-change: transform;
}

.ol-touch .ol-control button {
  font-size: 1.5em;
}

.ol-touch .ol-zoom-extent {
  top: 5.5em;
}

.ol-control button:focus,
.ol-control button:hover {
  text-decoration: none;
  background-color: rgba(80, 80, 80, 1);
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0;
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px;
}

.ol-attribution {
  text-align: right;
  bottom: 1.2em;
  right: 0.5em;
  max-width: 20em;
}

.ol-attribution ul {
  margin: 0;
  padding: 0em 1em;
  font-size: 1em;
  line-height: 1.375em;
  text-align: left;
  color: #fff;
}

.ol-attribution li {
  display: inline;
  list-style: none;
  line-height: inherit;
}

.ol-attribution li:not(:last-child):after {
  content: ' ';
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle;
}

.ol-attribution button,
.ol-attribution ul {
  display: inline-block;
}

.ol-attribution.ol-collapsed ul {
  display: none;
}

.ol-attribution:not(.ol-collapsed) {
  background: rgba(0, 0, 0, 1);
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0;
  height: 1.1em;
  line-height: 1em;
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -0.2em;
  max-height: 1.6em;
}

.ol-attribution.ol-uncollapsible button {
  display: none;
}

.ol-zoomslider {
  top: 4.5em;
  left: 0.5em;
  height: 200px;
}

.ol-zoomslider button {
  position: relative;
  height: 10px;
}

.ol-touch .ol-zoomslider {
  top: 5.5em;
}

.ol-overviewmap {
  left: 0.5em;
  bottom: 0.5em;
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0;
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: inline-block;
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid gray;
  height: 150px;
  margin: 2px;
  width: 150px;
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 1px;
  left: 2px;
  position: absolute;
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none;
}

.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255, 255, 255, 0.8);
}

.ol-overviewmap-box {
  border: 2px dotted rgba(80, 80, 80, 0.7);
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move;
}
